import { wrapperClass } from "./side-menu-content-padding.styles";
import { SideMenuContentPaddingProps } from "./side-menu-content-padding.types";

export const SideMenuContentPadding = ({ children, ...props }: SideMenuContentPaddingProps) => {
	return (
		<div className={wrapperClass}>
			<div {...props}>{children}</div>
		</div>
	);
};
