import { flex } from "@styled-system/patterns";

export const wrapperClass = flex({
	width: "full",
	maxWidth: "[57rem]",
	marginX: "auto",
	xl: {
		maxWidth: "[75rem]",
	},
	padding: "24",
	md: {
		padding: "40",
	},
});
