import { flex } from "@styled-system/patterns";
import { HORIZONTAL_PADDING_MEDIUM_UP, HORIZONTAL_PADDING_SMALL } from "./video-element.const";

export const wrapperClass = flex({
	flex: "1",
	flexDirection: "column",
	maxHeight: "full",
	xl: {
		height: "full",
	},
	paddingBottom: "0",
});

export const videoPlayerWrapperClass = flex({
	marginTop: "32",
	flexDirection: "column",
	overflow: "hidden",
	maxHeight: `[calc((100dvw - ${HORIZONTAL_PADDING_SMALL}rem) / (16/9))]`,
	md: {
		maxHeight: `[calc((100dvw - ${HORIZONTAL_PADDING_MEDIUM_UP}rem) / (16 / 9))]`,
	},
});

export const styledTabBarClass = flex({
	flex: "1",
	mt: "32",
});

export const transcriptsWrapperClass = flex({
	flexDirection: "column",
	overflowY: "auto",
	gap: "16",
	pt: "32",
	pb: "24",
	md: {
		pb: "40",
	},
	maxH: "[40vh]",
});

export const subtitleItemClass = flex({
	gap: "16",
});
