import { SliderChangeEvent } from "primereact/slider";
import { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { OnProgressProps } from "react-player/base";
import screenfull from "screenfull";
import {
	DynamicSlideMenuContext,
	DynamicSlideMenuProvider,
} from "@components/dynamic-slide-menu-provider";
import { Icon } from "@components/icon";
import { Slider } from "@components/slider";
import { VideoMenu } from "@components/video-menu";
import {
	HLSLevelAuto,
	VideoMenuRoute,
	VideoSettingsSpeed,
	VideoTranscriptLanguage,
} from "@components/video-menu/video-menu.const";
import { VideoPlayerSubtitles } from "@components/video-player-subtitles";
import { useWindowSize } from "@hooks/use-window-size";
import { brandStrong100, shade0 } from "@themes/colors";
import { TkaH2Span, TkaP3Span } from "@themes/font-tags";
import { VideoPlayerContext } from "./video-player.context";
import {
	HlsPlayer,
	OverlayInitiator,
	VideoPlayerProps,
	VideoPlayeWithProvidersProps,
} from "./video-player.interface";
import {
	backdropClass,
	bottomControlsClass,
	bottomShadowClass,
	centerControlsClass,
	centerPlayBackgroundClass,
	centerPlayControlsClass,
	centerPlayWrapperClass,
	centerRewindBackgroundClass,
	centerWrapperClass,
	controlsWrapperClass,
	initialPlayButtonClass,
	loadingAnimationClass,
	menuWrapperAnchorClass,
	menuWrapperClass,
	overlayClass,
	smallVideoTimeClass,
	splitControlsWrapperClass,
	styledReactPlayerClass,
	topControlsClass,
	topRightControlsClass,
	topShadowClass,
	transcriptsWrapperClass,
	volumeSliderClass,
	volumeSliderWrapperClass,
	volumeWrapperClass,
	wrapperClass,
} from "./video-player.styles";
import { formatSeconds } from "./video-player.utils";
import { HubspotHiddenGlobalStyle } from "../hubspot-style/hubspot-style.styles";
import { Skeleton } from "../skeleton";
import { getInitialTranscriptLanguage } from "../video-menu/video-menu.utils";

export const VideoPlayerWithProviders = ({
	onProgress,
	url,
	subtitles,
	thumbnail,
	onTranscriptLanguageChange,
	onSelectedSubtitlesChanged,
	onSubtitleIndexChanged,
}: VideoPlayeWithProvidersProps) => {
	const { path: menuPath, navigate } = useContext(DynamicSlideMenuContext);
	const playerIdRef = useRef(`video-player-id-${Math.floor(Math.random() * 100000)}`);

	const playerRef = useRef<ReactPlayer>(null);
	const [isPlaying, setIsPlaying] = useState(false);
	const [showInitialPlayButton, setShowInitialPlayButton] = useState(true);
	const [controlsVisible, setControlsVisible] = useState(false);
	const [isFullscreen, setIsFullscreen] = useState(false);
	const [isVolumeVisible, setVolumeVisible] = useState(false);
	const [volume, setVolume] = useState(1);
	const [playbackRate, setPlaybackRate] = useState(1);
	const availableSubtitleLanguages = subtitles?.map((subtitle) => subtitle.language);
	const [transcriptsLangugage, setTranscriptsLangugage] = useState(
		getInitialTranscriptLanguage(availableSubtitleLanguages),
	);
	const [availableHeights, setAvailableHeights] = useState<number[]>([]);
	const { isLargeUp, isMediumUp } = useWindowSize();
	const duration = playerRef.current?.getDuration() || 100;

	const hideMenu = useCallback(() => {
		menuPath.length > 0 && navigate(undefined, true);
	}, [menuPath.join("/"), navigate]);

	const hideVolume = useCallback(() => setVolumeVisible(false), []);

	const hideOtherOverlays = useCallback(
		(initiator: OverlayInitiator) => {
			if (initiator !== OverlayInitiator.Volume) {
				hideVolume();
			}
			if (
				initiator !== OverlayInitiator.Settings &&
				initiator !== OverlayInitiator.Transcripts
			) {
				hideMenu();
			}
		},
		[hideVolume, hideMenu],
	);

	const togglePlayPause = useCallback(
		(event: React.MouseEvent<SVGSVGElement | HTMLElement> | KeyboardEvent) => {
			event.stopPropagation();
			hideOtherOverlays(OverlayInitiator.OverlayButton);
			setIsPlaying((boo) => !boo);
			setShowInitialPlayButton(false);
		},
		[hideOtherOverlays],
	);

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if (event.code === "Space") {
				event.preventDefault();
				togglePlayPause(event);
			}
		};
		document.body.addEventListener("keydown", handleKeyDown);
		return () => {
			document.body.removeEventListener("keydown", handleKeyDown);
		};
	}, [togglePlayPause]);

	const settingsActive = menuPath.includes(VideoMenuRoute.settings.path);

	const handleSettingsOnClick = useCallback(
		(event: React.MouseEvent<HTMLElement>) => {
			event.stopPropagation();
			if (settingsActive) {
				hideMenu();
			} else {
				hideOtherOverlays(OverlayInitiator.Settings);
				navigate(VideoMenuRoute.settings.path, true);
			}
		},
		[settingsActive, navigate, hideOtherOverlays, hideMenu],
	);

	const transcriptsActive = menuPath.includes(VideoMenuRoute.transcripts.path);

	const handleTranscriptsOnClick = useCallback(
		(event: React.MouseEvent<HTMLElement>) => {
			event.stopPropagation();
			if (transcriptsActive) {
				hideMenu();
			} else {
				hideOtherOverlays(OverlayInitiator.Transcripts);
				navigate(VideoMenuRoute.transcripts.path, true);
			}
		},
		[transcriptsActive, navigate, hideOtherOverlays, hideMenu],
	);

	const handleOnTranscriptsLanguageChange = useCallback((language: VideoTranscriptLanguage) => {
		setTranscriptsLangugage(language);
		onTranscriptLanguageChange && onTranscriptLanguageChange(language);
	}, []);

	const selectedSubtitles = useMemo(
		() => subtitles?.find((subtitle) => subtitle.language == transcriptsLangugage),
		[subtitles, transcriptsLangugage],
	);

	useEffect(() => {
		onSelectedSubtitlesChanged?.(selectedSubtitles);
	}, [selectedSubtitles]);

	const handleOnProgress = useCallback(
		(progress: OnProgressProps) => {
			onProgress(progress.playedSeconds);
		},
		[onProgress],
	);

	const handleOnSlideEnd = useCallback(
		(event: SliderChangeEvent) => {
			event.originalEvent.stopPropagation();
			const position = Array.isArray(event.value) ? event.value[0] : event.value;
			playerRef.current?.seekTo(position, "seconds");
			hideOtherOverlays(OverlayInitiator.OverlayButton);
		},
		[playerRef.current, hideOtherOverlays],
	);

	const handleOnReady = useCallback(() => {
		const hlsPlayer = playerRef.current?.getInternalPlayer("hls") as HlsPlayer;
		if (!hlsPlayer) return;
		const availableLevels = hlsPlayer.levels.map((level) => level.height);
		setAvailableHeights(availableLevels);
	}, [playerRef.current]);

	const handleOnResolutionChanged = useCallback(
		(resolutionHeight: number) => {
			const hlsPlayer = playerRef.current?.getInternalPlayer("hls") as HlsPlayer;
			if (!hlsPlayer) return;
			const setAutoLevelSelection = () => {
				hlsPlayer.nextLevel = HLSLevelAuto;
				hlsPlayer.loadLevel = HLSLevelAuto;
			};
			if (resolutionHeight === HLSLevelAuto) {
				setAutoLevelSelection();
				return;
			}
			const nextLevel = hlsPlayer.levels.findIndex(
				(level) => level.height === resolutionHeight,
			);
			if (nextLevel !== undefined) {
				hlsPlayer.nextLevel = nextLevel;
				hlsPlayer.loadLevel = nextLevel;
				return;
			}
			setAutoLevelSelection();
		},
		[playerRef.current],
	);

	const handlePlaybackRateOnChange = useCallback((rate: VideoSettingsSpeed) => {
		setPlaybackRate(Number(rate));
	}, []);

	useEffect(() => {
		if (!screenfull.isEnabled) return;
		const onFullscreenChanged = () => {
			isFullscreen && !screenfull.isFullscreen && setIsFullscreen(false);
		};
		screenfull.on("change", onFullscreenChanged);
		return () => {
			screenfull.off("change", onFullscreenChanged);
		};
	}, [isFullscreen]);

	const handleFullScreenOnClick = useCallback(
		(event: React.MouseEvent<HTMLElement>) => {
			event.stopPropagation();
			hideOtherOverlays(OverlayInitiator.OverlayButton);
			if (screenfull.isEnabled) {
				if (isFullscreen) {
					screenfull.isEnabled && screenfull.exit();
				} else {
					const element = document.getElementById(playerIdRef.current);
					if (screenfull.isEnabled && element) {
						screenfull.request(element, { navigationUI: "hide" });
					}
				}
			}
			setIsFullscreen(!isFullscreen);
		},
		[playerIdRef.current, isFullscreen, hideOtherOverlays],
	);

	const handleRewind10OnClick = useCallback(
		(event: React.MouseEvent<HTMLElement>) => {
			event.stopPropagation();
			hideOtherOverlays(OverlayInitiator.OverlayButton);
			const time = playerRef.current?.getCurrentTime() ?? 0;
			playerRef.current?.seekTo(time - 10, "seconds");
		},
		[playerRef.current, hideOtherOverlays],
	);

	const handleForward10OnClick = useCallback(
		(event: React.MouseEvent<HTMLElement>) => {
			event.stopPropagation();
			hideOtherOverlays(OverlayInitiator.OverlayButton);
			const time = playerRef.current?.getCurrentTime() ?? 0;
			playerRef.current?.seekTo(time + 10, "seconds");
		},
		[playerRef.current, hideOtherOverlays],
	);

	const handleOnPause = useCallback(() => {
		setIsPlaying(false);
	}, []);

	const handleOnPlay = useCallback(() => {
		setIsPlaying(true);
	}, []);

	const handleOverlayClicked = useCallback(() => {
		menuPath.length === 0 && setControlsVisible((controlsVisible) => !controlsVisible);
		hideOtherOverlays(OverlayInitiator.OverlayButton);
	}, [hideOtherOverlays, menuPath]);

	const handleOnMouseLeave = useCallback(() => {
		if (isLargeUp) {
			hideOtherOverlays(OverlayInitiator.OutsideFocus);
		}
	}, [isLargeUp, hideOtherOverlays]);

	const handleOnVolumeClick = useCallback(
		(event: React.MouseEvent<HTMLElement>) => {
			event.stopPropagation();
			hideOtherOverlays(OverlayInitiator.Volume);
			setVolumeVisible((volumeVisible) => !volumeVisible);
		},
		[hideOtherOverlays],
	);

	const handleOnVolumeChange = useCallback((event: SliderChangeEvent) => {
		const position = Array.isArray(event.value) ? event.value[0] : event.value;
		setVolume(position);
	}, []);
	return (
		<div className={wrapperClass(isFullscreen)} id={playerIdRef.current}>
			<div className={centerWrapperClass(isFullscreen)}>
				<ReactPlayer
					className={styledReactPlayerClass}
					ref={playerRef}
					url={url}
					width={"100%"}
					height={"100%"}
					volume={volume}
					playing={isPlaying}
					onPause={handleOnPause}
					onPlay={handleOnPlay}
					progressInterval={0.2}
					onProgress={handleOnProgress}
					onReady={handleOnReady}
					playbackRate={playbackRate}
					playsinline={screenfull.isEnabled}
					config={{
						file: {
							attributes: {
								poster: thumbnail,
							},
						},
					}}
				/>
				<div className={backdropClass(!showInitialPlayButton)} />
				<div
					className={`${overlayClass({
						showBottomControls: controlsVisible,
					})} tka-video-controls`}
					onClick={handleOverlayClicked}
					onMouseLeave={handleOnMouseLeave}
				>
					<div className={topControlsClass}>
						<div className={transcriptsWrapperClass}>
							{selectedSubtitles &&
								transcriptsLangugage !== VideoTranscriptLanguage.Disabled && (
									<VideoPlayerSubtitles
										hidden={!isFullscreen}
										subtitles={selectedSubtitles.subtitles}
										onSubtitleIndexChanged={onSubtitleIndexChanged}
									/>
								)}
						</div>
						<div className={topRightControlsClass(controlsVisible)}>
							<div className={topShadowClass} />
							<Icon
								icon="transcripts"
								sizeInRem={1.25}
								active={transcriptsActive}
								tkaColor={shade0}
								onClick={handleTranscriptsOnClick}
							/>
							<Icon
								icon="settings"
								sizeInRem={1.25}
								active={settingsActive}
								tkaColor={shade0}
								onClick={handleSettingsOnClick}
							/>
							<Icon
								icon={isFullscreen ? "closeFullscreen" : "fullscreen"}
								sizeInRem={1.25}
								tkaColor={shade0}
								onClick={handleFullScreenOnClick}
							/>
						</div>
					</div>

					<div className={centerControlsClass}>
						{showInitialPlayButton ? (
							<div className={centerPlayWrapperClass}>
								<div className={initialPlayButtonClass} onClick={togglePlayPause}>
									<Icon
										icon={"playArrow"}
										sizeInRem={isLargeUp ? 2.35 : 1}
										tkaColor={shade0}
									/>
								</div>
								<TkaH2Span tkaColor={brandStrong100}>Abspielen</TkaH2Span>
							</div>
						) : (
							<div
								className={centerPlayControlsClass}
								hidden={isMediumUp || !controlsVisible}
							>
								<div
									className={centerRewindBackgroundClass}
									onClick={handleRewind10OnClick}
								>
									<Icon icon="rewind10" sizeInRem={1.5} tkaColor={shade0} />
								</div>
								<div
									className={centerPlayBackgroundClass}
									onClick={togglePlayPause}
								>
									<Icon
										icon={isPlaying ? "pauseArrow" : "playArrow"}
										sizeInRem={1}
										tkaColor={shade0}
									/>
								</div>
								<div
									className={centerRewindBackgroundClass}
									onClick={handleForward10OnClick}
								>
									<Icon icon="forward10" sizeInRem={1.5} tkaColor={shade0} />
								</div>
							</div>
						)}
						<div className={menuWrapperAnchorClass}>
							<div className={menuWrapperClass}>
								<VideoMenu
									availableHeights={availableHeights}
									onResolutionChanged={handleOnResolutionChanged}
									onSpeedChanged={handlePlaybackRateOnChange}
									onTranscriptLanguageChange={handleOnTranscriptsLanguageChange}
									availableSubtitleLanguages={availableSubtitleLanguages}
								/>
							</div>
						</div>
					</div>
					<div className={`${bottomControlsClass} tka-bottom-controls`}>
						<div className={bottomShadowClass} />
						{!isMediumUp && (
							<div className={smallVideoTimeClass}>
								<VideoPlayerContext.Consumer>
									{({ progress }) => (
										<TkaP3Span tkaColor={shade0}>{`${formatSeconds(
											progress,
										)} / ${formatSeconds(duration)}`}</TkaP3Span>
									)}
								</VideoPlayerContext.Consumer>
							</div>
						)}
						<VideoPlayerContext.Consumer>
							{({ progress }) => (
								<Slider
									rounded
									colorVersion="brandStrong"
									value={Math.min(duration, progress)}
									onSlideEnd={handleOnSlideEnd}
									max={duration}
									step={0.2}
								/>
							)}
						</VideoPlayerContext.Consumer>
						<div className={splitControlsWrapperClass}>
							<div className={controlsWrapperClass}>
								<Icon
									icon="rewind10"
									sizeInRem={1.25}
									tkaColor={shade0}
									onClick={handleRewind10OnClick}
								/>
								<Icon
									icon={isPlaying ? "pauseArrow" : "playArrow"}
									sizeInRem={1.25}
									tkaColor={shade0}
									onClick={togglePlayPause}
								/>
								<Icon
									icon="forward10"
									sizeInRem={1.25}
									tkaColor={shade0}
									onClick={handleForward10OnClick}
								/>
								<div className={volumeWrapperClass}>
									{isVolumeVisible && (
										<div className={volumeSliderWrapperClass}>
											<Slider
												className={volumeSliderClass}
												showHandle
												rounded
												value={volume}
												max={1}
												step={0.01}
												orientation="vertical"
												onChange={handleOnVolumeChange}
												colorVersion="brandStrong"
											/>
										</div>
									)}
									<Icon
										icon="volume"
										sizeInRem={1.25}
										active={isVolumeVisible}
										tkaColor={shade0}
										onClick={handleOnVolumeClick}
									/>
								</div>
								<VideoPlayerContext.Consumer>
									{({ progress }) => (
										<TkaP3Span tkaColor={shade0}>{`${formatSeconds(
											progress,
										)} / ${formatSeconds(duration)}`}</TkaP3Span>
									)}
								</VideoPlayerContext.Consumer>
							</div>
							<div className={controlsWrapperClass}>
								<Icon
									icon="transcripts"
									sizeInRem={1.25}
									active={transcriptsActive}
									tkaColor={shade0}
									onClick={handleTranscriptsOnClick}
								/>
								<Icon
									icon="settings"
									sizeInRem={1.25}
									active={settingsActive}
									tkaColor={shade0}
									onClick={handleSettingsOnClick}
								/>
								<Icon
									icon={isFullscreen ? "closeFullscreen" : "fullscreen"}
									sizeInRem={1.25}
									tkaColor={shade0}
									onClick={handleFullScreenOnClick}
								/>
							</div>
						</div>
					</div>
				</div>
				<Skeleton className={loadingAnimationClass} width="100%" height="100%" />
			</div>
			{isFullscreen && <HubspotHiddenGlobalStyle />}
		</div>
	);
};

const MemoizedVideoPlayerWithProviders = memo(VideoPlayerWithProviders);

export const VideoPlayer = (props: VideoPlayerProps) => {
	const [progress, setProgress] = useState(0);
	const handleOnProgress = useCallback((progress: number) => {
		setProgress(progress);
	}, []);
	return (
		<DynamicSlideMenuProvider>
			<VideoPlayerContext.Provider value={{ progress }}>
				<MemoizedVideoPlayerWithProviders {...props} onProgress={handleOnProgress} />
			</VideoPlayerContext.Provider>
		</DynamicSlideMenuProvider>
	);
};
