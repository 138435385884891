import { brandMain100, brandMain40, brandStrong100, shade20 } from "@themes/colors";
import { SliderStyleProps } from "./slider.types";

export const setBackgroundColor = ({ colorVersion = "brandMain" }: SliderStyleProps) => {
	switch (colorVersion) {
		case "brandMain":
			return brandMain40.toString();
		default:
			return shade20.toString();
	}
};

export const setFillColor = ({ colorVersion = "brandMain" }: SliderStyleProps) => {
	switch (colorVersion) {
		case "brandMain":
			return brandMain100.toString();
		default:
			return brandStrong100.toString();
	}
};
