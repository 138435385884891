import screenfull from "screenfull";
import { css } from "@styled-system/css";
import { flex } from "@styled-system/patterns";

export const wrapperClass = (fullScreen: boolean) =>
	flex({
		maxHeight: "full",
		maxWidth: "full",
		aspectRatio: "16/9",
		_landscape: {
			maxHeight: "[55dvh]",
		},
		...(fullScreen &&
			!screenfull.isEnabled && {
				position: "fixed",
				inset: "0",
				backgroundColor: "shade.100",
			}),

		...(fullScreen && {
			justifyContent: "center",
			alignItems: "center",
		}),
	});

export const centerWrapperClass = (fullScreen: boolean) =>
	css({
		position: "relative",
		maxHeight: "full",
		flexGrow: 1,
		borderRadius: "[10px]",
		overflow: "hidden",
		backgroundColor: "[transparent]",
		zIndex: 10,
		maxWidth: fullScreen ? "[calc(100dvh * 16/9)]" : "full",
		...(fullScreen && { borderRadius: "[none]" }),
	});

export const styledReactPlayerClass = css({
	aspectRatio: "16/9",
	height: "auto!",
	maxHeight: "full",
});

export const backdropClass = (hidden?: boolean) =>
	flex({
		position: "absolute",
		inset: "0",
		backgroundColor: "shade.80",
		opacity: hidden ? 0 : 0.6,
		transition: "[opacity 200ms ease-out]",
	});

export const overlayClass = ({
	hidden,
	showBottomControls,
}: { hidden?: boolean; showBottomControls?: boolean } = {}) =>
	flex({
		position: "absolute",
		inset: "0",
		flexDirection: "column",
		transition: "[opacity 100ms ease-out]",
		...(hidden && { display: "none" }),
		"&.tka-video-controls .tka-bottom-controls": {
			...(showBottomControls ? {} : { display: "none" }),
			lg: {
				display: "none",
			},
		},
		"&.tka-video-controls:hover .tka-bottom-controls": {
			lg: {
				display: "block",
			},
		},
	});

export const topControlsClass = flex({
	position: "relative",
	flexDirection: "row",
	justifyContent: "space-between",
	minHeight: "0",
	gap: "24",
	paddingLeft: "12",
	paddingRight: "12",
	paddingTop: "12",
	_landscape: {
		paddingLeft: "20",
		paddingRight: "20",
		paddingTop: "16",
	},
	zIndex: 10,
});

export const topShadowClass = css({
	position: "absolute",
	top: "0",
	left: "0",
	right: "0",
	height: "[3rem]",
	md: {
		display: "none",
	},
	zIndex: -1,
	background: "[linear-gradient(180deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.00) 98.48%)]",
});

export const transcriptsWrapperClass = flex({
	flexGrow: 1,
	justifyContent: "center",
});

export const topRightControlsClass = (showControls?: boolean) =>
	flex({
		md: {
			display: "none",
		},
		flexDirection: "row",
		gap: "24",
		...(showControls ? {} : { display: "none" }),
	});

export const centerControlsClass = flex({
	justifyContent: "flex-end",
	flexGrow: 1,
	minHeight: "0",
	padding: "12",
	md: {
		padding: "20",
	},
});
export const initialPlayButtonClass = flex({
	alignItems: "center",
	justifyContent: "center",
	width: "[3.5rem]",
	height: "[3.5rem]",
	backgroundColor: "secondary.100",
	lg: {
		width: "[6.5rem]",
		height: "[6.5rem]",
	},
	md: {
		paddingLeft: "8",
	},
	borderRadius: "[50%]",
	paddingLeft: "4",
});

export const centerPlayBackgroundClass = flex({
	alignItems: "center",
	justifyContent: "center",
	width: "[3.5rem]",
	height: "[3.5rem]",
	backgroundColor: "shade.100/50",
	borderRadius: "[1.75rem]",
});

export const centerRewindBackgroundClass = flex({
	alignItems: "center",
	justifyContent: "center",
	width: "[2.75rem]",
	height: "[2.75rem]",
	backgroundColor: "shade.100/50",
	borderRadius: "[1.375rem]",
});

export const bottomControlsClass = flex({
	position: "relative",
	flexDirection: "column",
	paddingLeft: "12",
	paddingRight: "12",
	paddingBottom: "12",
	md: {
		paddingLeft: "20",
		paddingRight: "20",
		paddingBottom: "20",
	},
	zIndex: 10,
});

export const bottomShadowClass = css({
	position: "absolute",
	bottom: "0",
	left: "0",
	right: "0",
	height: "[3.5rem]",
	md: {
		height: "[5.5rem]",
	},
	zIndex: -1,
	background: "[linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0.00) 98.48%)]",
});

export const menuWrapperAnchorClass = css({
	position: "relative",
});

export const menuWrapperClass = flex({
	position: "absolute",
	bottom: "0",
	right: "0",
	maxHeight: "full",
	zIndex: 10,
});

export const splitControlsWrapperClass = css({
	display: "none",
	"@media (min-width: 768px)": {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		paddingTop: "16",
	},
});

export const controlsWrapperClass = flex({
	flexDirection: "row",
	gap: "24",
	alignItems: "center",
});

export const smallVideoTimeClass = css({
	paddingBottom: "12",
});

export const volumeWrapperClass = css({
	position: "relative",
});

export const volumeSliderWrapperClass = css({
	position: "absolute",
	bottom: "64",
	left: "[-1]",
	backgroundColor: "primary.100/90",
	borderRadius: "10",
	padding: "24",
});

export const volumeSliderClass = css({
	height: "[7rem]",
});

export const loadingAnimationClass = css({
	position: "absolute",
	inset: "0",
	zIndex: -10,
});

export const centerPlayWrapperClass = css({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	gap: "16",
	position: "absolute",
	left: "[50%]",
	top: "[50%]",
	transform: "translate(-50%, -50%)",
});

export const centerPlayControlsClass = css({
	position: "absolute",
	top: "[50%]",
	left: "[50%]",
	transform: "translate(-50%, -50%)",
	display: "flex",
	alignItems: "center",
	gap: "24",
});
